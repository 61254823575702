<template>
	<div class="login">

		

		<!-- Login -->
		<div class="login__block active pb-4" id="l-login" v-if='page == "login"'>
			<div class="login__block__header">
				<img src="https://static.ucraft.net/fs/ucraft/userFiles/eclipse-ndt/images/110-artboard-1-2.png?v=1604893568" style="max-width:250px;">
				Hi there! Please Sign in
			</div>

			<div class="login__block__body mb-2 pb-0">

				<Message v-if="success_msg.length > 0" type="success" :message="success_msg" />

				<form @submit.prevent="validateLoginSubmit()" data-vv-scope="login">
					<div class="form-group mb-0">
						<input :class="{'is-invalid': get_error('email').length > 0}" v-model="login.email" type="text" class="form-control" placeholder="Email Address">
					</div>
					<span v-show="get_error('email').length > 0" class="text-danger">{{ get_error('email') }}</span>

					<div class="form-group mt-4 mb-0">
						<input :class="{'is-invalid': get_error('password').length > 0}" v-model="login.password" type="password" class="form-control" placeholder="Password">
					</div>
					<span v-show="get_error('password').length > 0" class="text-danger">{{ get_error('password') }}</span>
					<br/>
					<ErrorMessage class='mt-4 mb-2' v-if="error_msg.length > 0" type="error" :message="error_msg" />

					<button type='submit' class="btn btn-theme mt-4">Login</button>
				</form>
			</div>
		</div>
		
		<!-- Forgot Password -->
		<div class="login__block" id="l-forget-password" v-if='page == "recover"'>
			<div class="login__block__header">
				<i class="zwicon-user-circle"></i>
				Forgot Password?
			</div>

			<div class="login__block__body">
				<form @submit.prevent="validateRecoverySubmit()" >
					<p class="mb-4">Please enter the email associated with your account to receive recovery email.</p>

					<div class="form-group mt-2 mb-3">
						<input :class="{'is-invalid': get_error('recover').length > 0}" type="text" name='email' class="form-control" v-model='recovery_email' placeholder="Email Address"> 
					</div>
					<span v-show="get_error('recover').length > 0" class="text-danger mb-1">{{ get_error('recover') }}</span>
					<br/>

					<button type='submit' class="btn btn-theme mt-4">Reset Password</button>
				</form>
			</div>
			<a @click.prevent='page = "login"' class='forgot-link mt-1 mb-3'><i class="zwicon-chevron-left"></i> <u>Back to Login</u></a>
		</div>
	</div>
	
</template>

<style scoped lang="scss">
	.logo{
		height: 90px !important;
		width: auto !important;
		min-width: auto !important;
		box-shadow: none !important;
		-webkit-box-shadow: none !important;
	}
	.login__block{
		display: block;
	}
	.forgot-link{
		display: inline-block;
		position: absolute;
		bottom: -40px;
		left: 0px;
		width: 100%;
	}
	.google-signup{
		width: 100px;
		background-color: #fff;
		color: #444;
		padding: 6px 10px 7px;
		margin-top: 5px;
		display:inline-block;

		span{
			margin-left: 4px;
		}

		&:hover{
				-webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
				box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
		}
	}
</style>

<script>

	


	import store from "@/store/";

	import { LOGIN, REQUEST_RESET } from "@/store/actions.type";

	import Message from "@/components/General/Message";
	import ErrorMessage from "@/components/General/ErrorMessage";

	// Language / Localization
	export default {
		name: "Login",
		components: {
			Message,
			ErrorMessage
		},
		props: {
			error: {
				type: String,
				default: ""
			},
			success: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				login: {
					email: '',
					password: '',
					rememberme: false
				},
				errors: {
					password: '',
					email: '',
					recover: ''
				},
				recovery_email: '',
				page: 'login',
				success_msg: this.success,
				error_msg: this.error
			}
		},
		methods: {
			validateLoginSubmit() {
				// Clear errors
				this.errors = {
					password: '',
					email: '',
					recover: ''
				};
				this.error_msg = '';

				// Attempt validation
				if(this.login.email.trim().length <= 1) {
					this.errors.email = "The email address you entered was invalid or missing.";
					return;
				}
				// Validate email format
				if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.login.email)) {
					this.errors.email = "The email address you entered was invalid or missing.";
					return;
				}
				// Validate password
				if(this.login.password.trim().length <= 1) {
					this.errors.password = "The password you entered was invalid or missing.";
					return;
				}
				// Our form has been validated
				this.attempt_login();
			},
			attempt_login(){
				store.dispatch(LOGIN, this.login).then(() => {
					this.$router.push({ name: 'inventory', params: { success: "You have been successfully signed in." }});
				}).catch(message => {
					this.success_msg = '';
					this.error_msg = message;
				});
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			recover_toggle(){
				this.page = (this.page == "login"?"recover":"login");
				this.recovery_email = "";
			},
			validateRecoverySubmit() {
				// Clear errors
				this.error_msg = '';
				this.errors = {
					password: '',
					email: '',
					recover: ''
				};

				// Attempt validation
				if(this.recovery_email.trim().length <= 1) {
					this.errors.recover = "The email address you entered was invalid or missing.";
					return;
				}
				// Validate email format
				if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.recovery_email)) {
					this.errors.recover = "The email address you entered was invalid or missing.";
					return;
				}
				// Our form has been validated
				this.attempt_recovery();
			},
			attempt_recovery(){
				console.log("Attempt to send recovery email");
				store.dispatch(REQUEST_RESET, this.recovery_email).then(() => {
					this.success_msg = "Recovery email has been sent.";
					this.page = "login";
				}).catch(message => {
					this.success_msg = '';
					this.errors.recover = message;
				});
			}
		}
	}
</script>